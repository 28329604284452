.section-not-found {
  padding-top: 100px;
  padding-bottom: 250px;
  @include flexbox(center, center);
  .error-body {
    position: relative;
    @include flexbox(center, center);
    flex-direction: column;
    text-align: center;
    .image-container {
      position: relative;
      background-image: url("../assets/images/background/404.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 600px;
      height: 400px;
      margin-bottom: -30px;
      .sphere-medium-container {
        position: absolute;
        left: 32%;
        top: 21%;
        z-index: 99;
        animation: rotate-1 6s ease-in-out infinite;
        width: 230px;
        height: 230px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    h1 {
      color: #fff;
      font-size: 128px;
      font-weight: 700;
    }
    h2 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 35px;
      font-weight: 700;
    }
    p {
      color: rgba(0, 0, 0, 0.87);
      font-size: 35px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 62px;
    }
    .btn-return {
      display: inline-block;
      color: #fff;
      font-size: 21px;
      font-weight: 700;
      letter-spacing: -0.7px;
      padding: 15px 40px;
      transition: all 0.3s ease-in;
      border-radius: 12px;
      background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
      box-shadow: 2px 5px 0 #888;
      min-width: 210px;
      &:hover {
        box-shadow: none;
        transform: translate(2px, 5px);
      }
    }
  }
}


@media screen and (max-width: 991.98px) {
  .section-not-found {
    padding-top: unset;
    padding-bottom: 100px;
    .error-body {
      .image-container {
        background-image: url("../assets/images/background/404.svg");
        width: 300px;
        height: 300px;
        margin-bottom: -60px;
        .sphere-medium-container {
          left: 31%;
          top: 31%;
          animation: rotate-1 6s ease-in-out infinite;
          width: 120px;
          height: 120px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      h1 {
        color: #fff;
        font-size: 58px;
        font-weight: 600;
      }
      h2 {
        color: rgba(0, 0, 0, 0.87);
        font-size: 24px;
        font-weight: 600;
      }
      p {
        font-size: 18px;
        margin: 10px 0 20px 0;
      }
      .btn-return {
        font-size: 18px;
        font-weight: 600;
        padding: 12px 24px;
        min-width: 100%;
      }
    }
  }
}
.section-amount-main {
  padding: 150px 0;
  .container {
    position: relative;
    .sphere-small-container {
      position: absolute;
      top: -60px;
      left: -40px;
      z-index: 99;
      animation: rotate-2 6s ease-in-out infinite;
    }
    .sphere-medium-container {
      position: absolute;
      right: 30px;
      bottom: -90px;
      z-index: 99;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .content {
      position: relative;
      z-index: 999;
      @include flexbox(space-between, center);
      flex-wrap: wrap;
      padding: 50px 30px;
      border-radius: 32px;
      background: linear-gradient(
        95deg,
        rgba(255, 255, 255, 0.4) -14.5%,
        rgba(255, 255, 255, 0.1) 118.65%
      );
      backdrop-filter: blur(25px);
      &-item {
        @include flexbox(center, center);
        flex-direction: column;
        gap: 8px;
        flex: 1 1 450px;
        padding: 70px 0;
        &:first-child {
          border-right: 1px solid rgba(105, 145, 88, 0.53);
        }
        p {
          @include flexbox(center, center);
          gap: 6px;
          color: rgba(0, 0, 0, 0.68);
          text-align: center;
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          span {
            text-align: center;
            font-size: 48px;
            font-weight: 700;
            line-height: 56px;
            color: #699158;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .section-amount-main {
    padding: 120px 0;
    .container {
      .sphere-small-container {
        top: -60px;
        left: 10px;
      }
      .sphere-medium-container {
        right: 20px;
        bottom: -110px;
      }
      .content {
        &-item {
          padding: 40px 0;
          &:first-child {
            border-right: unset;
            border-bottom: 1px solid rgba(105, 145, 88, 0.53);
          }
        }
      }
    }
  }
}

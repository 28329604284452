.section-partners {
  padding: 45px 0;
  background-color: #fff;
  .swiper-slide {
    user-select: none;
    @include flexbox(center, center);
    gap: 12px;
    cursor: pointer;
    img {
      height: 80px;
      width: 80px;
      object-fit: cover;
      border-radius: 50%;
    }
    p {
      color: rgba(0, 0, 0, 0.87);
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: 992.98px) {
  .section-partners {
    padding: 40px 0;
  }
}

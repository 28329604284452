@keyframes rotate-1 {
  0% {
    transform: rotate(0) translateY(0);
  }
  50% {
    transform: rotate(50deg) translateY(0);
  }
  100% {
    transform: rotate(0) translateY(0);
  }
}
@keyframes rotate-2 {
  0% {
    transform: rotate(0) translateY(0);
  }
  50% {
    transform: rotate(-50deg) translateY(0);
  }
  100% {
    transform: rotate(0) translateY(0);
  }
}
@keyframes left-right-animation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes top-bottom-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes scale-1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale-2 {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes line-element {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  scroll-behavior: smooth;
  font-family: Montserrat, sans-serif;
  background: linear-gradient(0deg, #ebefec 0%, #ebefec 100%), linear-gradient(158deg, #14171c 2.09%, #14171c 137.51%);
  overflow-x: hidden;
}

.app {
  padding-top: 120px;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

input,
textarea {
  border: none;
  background: none;
}

.container {
  padding: 0 15px;
  max-width: 1450px;
  margin: 0 auto;
}

.line-element {
  height: 2px;
  background-size: 300% 100%;
  animation: line-element 6s linear infinite;
  margin: 12px 0;
  filter: drop-shadow(0px 16px 30px #699158);
  border-radius: 12px;
  background-image: linear-gradient(90deg, rgb(206, 206, 206), #699158, rgba(104, 124, 106, 0.68), #699158);
}

.Toastify__toast-container--top-center {
  z-index: 999999 !important;
}

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #699158;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  content: "" !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.swiper-button-prev {
  background-image: url("../assets/icons/main/arrow-left.svg");
  transition: 0.3s ease-in;
  width: 35px;
  height: 35px;
  padding: 20px;
}
.swiper-button-prev:hover {
  transform: rotate(-360deg);
}

.swiper-button-next {
  background-image: url("../assets/icons/main/arrow-right.svg");
  transition: 0.3s ease-in;
  width: 35px;
  height: 35px;
  padding: 20px;
}
.swiper-button-next:hover {
  transform: rotate(360deg);
}

.page-main {
  padding-top: 110px;
}

@media screen and (max-width: 767.98px) {
  .mobile-hidden-2 {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .mobile_hidden {
    display: none;
  }
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
.section-hero {
  position: relative;
  height: calc(100vh - 290px);
}
.section-hero .container {
  height: 100%;
}
.section-hero .container .content {
  position: relative;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}
.section-hero .container .content h1 {
  background: linear-gradient(90deg, #b4adc1 5.26%, #8fb698 45.69%, #4f9750 64.36%, #7c7a80 80%);
  max-width: 850px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 75px;
  font-weight: 700;
  line-height: 84px;
  margin: 0 auto;
}
.section-hero .container .content p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  margin-top: 20px;
  max-width: 1000px;
}
.section-hero .container .content a {
  display: inline-block;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.7px;
  padding: 15px 40px;
  transition: all 0.3s ease-in;
  border-radius: 12px;
  background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
  box-shadow: 2px 5px 0 #888;
  min-width: 210px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.section-hero .container .content a:hover {
  box-shadow: none;
  transform: translate(2px, 5px);
}
.section-hero .sphere-big-container {
  position: absolute;
  left: -40px;
  bottom: -50px;
  z-index: 999;
  animation: top-bottom-animation 6s ease-in-out infinite;
}
.section-hero .sphere-small-container {
  position: absolute;
  top: 50px;
  left: 150px;
  z-index: 999;
  animation: rotate-2 6s ease-in-out infinite;
}
.section-hero .sphere-extra-small-container {
  position: absolute;
  bottom: 50px;
  right: 150px;
  z-index: 999;
  animation: rotate-1 6s ease-in-out infinite;
}
.section-hero .element-container {
  position: absolute;
  top: -140px;
  right: 0;
  z-index: 999;
  animation: left-right-animation 6s ease infinite;
}

@media screen and (max-width: 992.98px) {
  .section-hero {
    height: calc(100vh - 300px);
  }
  .section-hero .container .content h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .section-hero .container .content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .section-hero .container .content a {
    text-align: center;
    width: 100%;
  }
  .section-hero .sphere-big-container {
    max-width: 120px;
    max-height: 120px;
  }
  .section-hero .sphere-big-container img {
    max-width: 100%;
    max-height: 100%;
  }
  .section-hero .sphere-small-container {
    top: -50px;
    left: 15px;
    max-width: 70px;
    max-height: 70px;
  }
  .section-hero .sphere-small-container img {
    max-width: 100%;
    max-height: 100%;
  }
  .section-hero .sphere-extra-small-container {
    bottom: 100px;
    right: 20px;
    animation: rotate-1 6s ease-in-out infinite;
  }
  .section-hero .element-container {
    top: -70px;
    right: 20px;
    animation: left-right-animation 6s ease infinite;
    max-width: 100px;
    max-height: 100px;
  }
  .section-hero .element-container img {
    max-width: 100%;
    max-height: 100%;
  }
}
.section-benefits {
  padding-top: 110px;
  padding-bottom: 180px;
}
.section-benefits .content {
  position: relative;
}
.section-benefits .content .info-text {
  margin-bottom: 52px;
}
.section-benefits .content .info-text h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
}
.section-benefits .content .info-text p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  max-width: 650px;
  margin-top: 8px;
}
.section-benefits .content .sphere-medium-container {
  position: absolute;
  right: -80px;
  bottom: -80px;
  z-index: 99;
  animation: rotate-1 6s ease-in-out infinite;
}
.section-benefits .content .body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 40px;
}
.section-benefits .content .body .item {
  position: relative;
  z-index: 998;
  flex-direction: column;
  padding: 32px 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 0.5px solid #699158;
  border-radius: 12px;
  background: linear-gradient(226deg, rgba(255, 255, 255, 0.05) 24.07%, rgba(255, 255, 255, 0.01) 98.32%);
  -webkit-backdrop-filter: blur(22px);
          backdrop-filter: blur(22px);
}
.section-benefits .content .body .item:hover {
  transform: translateY(-10px);
  border: 0.5px solid #699158;
}
.section-benefits .content .body .item h2 {
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}
.section-benefits .content .body .item p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.section-benefits .content .body .item .line-element {
  margin: 24px 0;
}

@media screen and (max-width: 992.98px) {
  .section-benefits {
    padding-top: 80px;
    padding-bottom: 180px;
  }
  .section-benefits .content .info-text {
    margin-bottom: 32px;
  }
  .section-benefits .content .info-text h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .section-benefits .content .info-text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .section-benefits .content .body {
    gap: 25px;
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.services-amount-body {
  padding-top: 200px;
  padding-bottom: 200px;
}
.services-amount-body .container {
  position: relative;
}
.services-amount-body .container .sphere_big_wrapper,
.services-amount-body .container .sphere_small_wrapper {
  position: absolute;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
.services-amount-body .container .sphere_big_wrapper {
  left: 20px;
  bottom: -90px;
  animation: rotate-2 6s ease-in-out infinite;
}
.services-amount-body .container .sphere_small_wrapper {
  top: -40px;
  right: 30px;
  animation: rotate-1 6s ease-in-out infinite;
}
.services-amount-body .services-amount-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  gap: 24px;
  flex-wrap: wrap;
  border-radius: 32px;
  background: linear-gradient(95deg, rgba(255, 255, 255, 0.4) -14.5%, rgba(255, 255, 255, 0.1) 118.65%);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  padding: 32px 64px;
  z-index: 99;
  position: relative;
}
.services-amount-body .services-amount-wrapper .item-container {
  flex: 1 1 300px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-right: 1.5px solid rgba(105, 145, 88, 0.53);
  padding: 40px 0;
  transition: 0.3s ease-in;
}
.services-amount-body .services-amount-wrapper .item-container:last-child {
  border-right: unset;
}
.services-amount-body .services-amount-wrapper .item-container:hover {
  transform: translateY(-8px);
}
.services-amount-body .services-amount-wrapper .item-container h3 {
  display: flex;
  gap: 12px;
  color: #699158;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 10px;
}
.services-amount-body .services-amount-wrapper .item-container h3 p {
  color: #699158;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}
.services-amount-body .services-amount-wrapper .item-container p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-wrap: nowrap;
}

@media screen and (min-width: 993px) and (max-width: 1105px) {
  .services-amount-body .services-amount-wrapper .item-container:nth-child(2) {
    border-right: unset;
  }
}
@media screen and (min-width: 678px) and (max-width: 977px) {
  .services-amount-body .services-amount-wrapper .item-container:nth-child(3) {
    border-bottom: unset;
  }
}
@media screen and (max-width: 992.98px) {
  .services-amount-body {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .services-amount-body .container .sphere_big_wrapper {
    top: -60px;
    left: -50px;
  }
  .services-amount-body .container .sphere_small_wrapper {
    top: 400px;
    right: -10px;
  }
  .services-amount-body .services-amount-wrapper {
    gap: unset;
    padding: 24px;
  }
  .services-amount-body .services-amount-wrapper .item-container {
    border-right: unset;
    border-bottom: 1.5px solid rgba(105, 145, 88, 0.53);
    justify-content: center;
    padding: 60px 0;
  }
  .services-amount-body .services-amount-wrapper .item-container:hover {
    transform: unset;
  }
  .services-amount-body .services-amount-wrapper .item-container:last-child {
    border-bottom: unset;
  }
}
.section-amount-main {
  padding: 150px 0;
}
.section-amount-main .container {
  position: relative;
}
.section-amount-main .container .sphere-small-container {
  position: absolute;
  top: -60px;
  left: -40px;
  z-index: 99;
  animation: rotate-2 6s ease-in-out infinite;
}
.section-amount-main .container .sphere-medium-container {
  position: absolute;
  right: 30px;
  bottom: -90px;
  z-index: 99;
  animation: rotate-1 6s ease-in-out infinite;
}
.section-amount-main .container .content {
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 50px 30px;
  border-radius: 32px;
  background: linear-gradient(95deg, rgba(255, 255, 255, 0.4) -14.5%, rgba(255, 255, 255, 0.1) 118.65%);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.section-amount-main .container .content-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 450px;
  padding: 70px 0;
}
.section-amount-main .container .content-item:first-child {
  border-right: 1px solid rgba(105, 145, 88, 0.53);
}
.section-amount-main .container .content-item p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: rgba(0, 0, 0, 0.68);
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
.section-amount-main .container .content-item p span {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #699158;
}

@media screen and (max-width: 992.98px) {
  .section-amount-main {
    padding: 120px 0;
  }
  .section-amount-main .container .sphere-small-container {
    top: -60px;
    left: 10px;
  }
  .section-amount-main .container .sphere-medium-container {
    right: 20px;
    bottom: -110px;
  }
  .section-amount-main .container .content-item {
    padding: 40px 0;
  }
  .section-amount-main .container .content-item:first-child {
    border-right: unset;
    border-bottom: 1px solid rgba(105, 145, 88, 0.53);
  }
}
.section-information {
  padding-top: 70px;
  padding-bottom: 90px;
  background: #fff;
}
.section-information .content-main h2 {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 70px;
}
.section-information .content-main ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
}
.section-information .content-main ul li {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  width: 350px;
}
.section-information .content-main ul li:nth-child(1) {
  background-image: url("../assets/images/background/1.svg");
}
.section-information .content-main ul li:nth-child(2) {
  background-image: url("../assets/images/background/2.svg");
}
.section-information .content-main ul li:nth-child(3) {
  background-image: url("../assets/images/background/3.svg");
}
.section-information .content-main ul li .solution-info {
  position: absolute;
  bottom: -20px;
  left: 15px;
}
.section-information .content-main ul li .solution-info span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  transition: all 0.3s ease-in;
}
.section-information .content-main ul li .solution-info p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  max-width: 350px;
}

@media screen and (max-width: 992.98px) {
  .section-information .content-main h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .section-information .content-main ul {
    gap: 60px;
    display: flex;
    justify-content: unset;
    align-items: unset;
  }
  .section-information .content-main ul li {
    height: 170px;
  }
  .section-information .content-main ul li .solution-info {
    left: unset;
  }
  .section-information .content-main ul li .solution-info span {
    font-size: 20px;
    font-weight: 600;
    margin-left: unset;
  }
  .section-information .content-main ul li .solution-info p {
    font-size: 18px;
    max-width: unset;
    margin-left: unset;
  }
}
.section-customer {
  padding: 90px 0;
  background-color: #fff;
}
.section-customer .reviews-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
  text-align: center;
}
.section-customer .reviews-header span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
}
.section-customer .reviews-header h2 {
  color: rgba(0, 0, 0, 0.68);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 24px;
  max-width: 1000px;
}
.section-customer .reviews-body .swiper-container {
  padding: 20px 10px;
}
.section-customer .reviews-body .swiper-container .swiper-slide {
  border-radius: 32px;
  border: 1px solid rgba(102, 102, 102, 0.12);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.section-customer .reviews-body .swiper-container .swiper-slide img {
  width: 100%;
  height: 330px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 32px 32px 0 0;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info {
  padding: 20px 12px;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info .name-rate-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info .name-rate-container h2 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info .name-rate-container .rate {
  color: #699158;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info p {
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.section-customer .reviews-body .swiper-container .swiper-slide {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.section-customer .reviews-body .swiper-container .swiper-slide-active {
  transform: scale(1.01);
  border: 0.7px solid #699158;
  background: var(--Foundation-Green-green-50, #f1f5f0);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}

@media screen and (max-width: 992.98px) {
  .section-customer {
    padding: 70px 0;
  }
  .section-customer .reviews-header {
    margin-bottom: 40px;
  }
  .section-customer .reviews-header span {
    font-size: 32px;
    line-height: 40px;
  }
  .section-customer .reviews-header h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
  }
  .section-customer .reviews-body .swiper-container .swiper-slide {
    border-radius: 16px;
  }
  .section-customer .reviews-body .swiper-container .swiper-slide .user-info {
    padding: 12px;
  }
  .section-customer .reviews-body .swiper-container .swiper-slide img {
    height: 280px;
    border-radius: 16px 16px 0 0;
  }
}
.section-partners {
  padding: 45px 0;
  background-color: #fff;
}
.section-partners .swiper-slide {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.section-partners .swiper-slide img {
  height: 80px;
  width: 80px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.section-partners .swiper-slide p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 700;
  line-height: 18px;
}

@media screen and (max-width: 992.98px) {
  .section-partners {
    padding: 40px 0;
  }
}
.telegram-agents-contacts {
  padding: 45px 15px;
  border-radius: 12px;
  background: #fff;
  margin-top: 150px;
  margin-bottom: 90px;
}
.telegram-agents-contacts .agents-contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
}
.telegram-agents-contacts .agents-contacts-item {
  flex: 1 1 300px;
  padding: 20px;
}
.telegram-agents-contacts .agents-contacts-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
.telegram-agents-contacts .agents-contacts-item a .telegram-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #f0f4ee;
  border-radius: 50%;
  transition: 0.3s ease-in;
}
.telegram-agents-contacts .agents-contacts-item a .telegram-image-container img {
  width: 24px;
  height: 24px;
  margin-left: -5px;
}
.telegram-agents-contacts .agents-contacts-item a .telegram-image-container:hover {
  transform: rotate(360deg);
}
.telegram-agents-contacts .agents-contacts-item a span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

@media screen and (max-width: 992.98px) {
  .telegram-agents-contacts {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 110px;
    margin-bottom: unset;
    border-radius: unset;
  }
  .telegram-agents-contacts .agents-contacts {
    gap: unset;
  }
  .telegram-agents-contacts .agents-contacts-item {
    padding: 25px 0;
  }
  .telegram-agents-contacts .agents-contacts-item a {
    justify-content: flex-start;
    max-width: 350px;
    margin: 0 auto;
  }
  .telegram-agents-contacts .agents-contacts-item a span {
    font-size: 18px;
    font-weight: 600;
  }
}
.footer {
  background: #131313;
}
.footer .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: unset;
  gap: 50px;
  flex-wrap: wrap;
  padding-top: 80px;
  padding-bottom: 50px;
  border-top: 0.3px solid rgba(255, 255, 255, 0.6);
}
.footer .footer-content .footer-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}
.footer .footer-content .footer-info .main-logo {
  min-height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
}
.footer .footer-content .footer-info .footer-short-info {
  max-width: 500px;
  color: #c8c8c8;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
}
.footer .footer-content .footer-info .footer-social-network {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}
.footer .footer-content .footer-info .footer-social-network a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #323132;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
.footer .footer-content .footer-info .footer-social-network a:hover {
  transform: rotate(360deg);
}
.footer .footer-content .footer-info .footer-social-network a img {
  width: 16px;
  height: 16px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-left: -3px;
}
.footer .footer-content .footer-nav h2 {
  color: #efeeef;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.footer .footer-content .footer-nav ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer .footer-content .footer-nav ul li a {
  color: #c8c8c8;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
}
.footer .footer-content .footer-nav ul li a.active {
  color: #769c65;
}
.footer .copyright-area {
  padding-top: 10px;
  padding-bottom: 30px;
}
.footer .copyright-area p {
  color: #a8a8a8;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width: 992.98px) {
  .footer .footer-content {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.header {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  transition: all 0.3s ease;
  padding: 19px 0;
}
.header.fixed {
  background: #ffffff;
  padding: 10px 0;
  border-bottom: 0.2px solid #699158;
  border-radius: 0 0 16px 16px;
}
.header .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .header-container .mobile-btn-open img {
  width: 28px;
  height: 28px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: 8px;
}
.header .header-container .logo-container {
  height: 65px;
  width: 125px;
}
.header .header-container .logo-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: drop-shadow(1px 1px -1px black);
}
.header .header-container .nav-container ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
}
.header .header-container .nav-container-item {
  color: rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 400;
  transition: 0.2s ease-in;
  text-transform: uppercase;
  line-height: 20px;
  padding-bottom: 2px;
}
.header .header-container .nav-container-item:hover {
  color: rgba(0, 0, 0, 0.87);
}
.header .header-container .nav-container-item.active {
  color: rgba(0, 0, 0, 0.97);
  border-bottom: 2px solid #699158;
}
.header .header-container .contact-container {
  transition: 0.4s ease-in-out;
}
.header .header-container .contact-container:hover {
  transform: rotate(360deg);
}
.header .header-container .contact-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 40px;
  background-color: #769c65;
  border-radius: 50%;
}
.header .header-container .contact-container a img {
  width: 20px;
  height: 20px;
  margin-left: -5px;
}
.header .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  padding: 35px 25px;
  background: #fff;
  transition: all 0.3s ease-out;
  transform: translateX(-100vw);
  transition: 0.3s ease-in;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.header .sidebar-header .close-button {
  float: right;
}
.header .sidebar-body {
  margin-top: 150px;
}
.header .sidebar-body li {
  padding: 12px 0;
  text-align: center;
}
.header .sidebar-body li a {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}
.header .sidebar-body li a.active {
  color: #509851 !important;
}

#show .sidebar {
  transform: translateX(0vw);
  min-width: 80%;
}
#show .sidebar .menu-inner {
  top: 0;
  height: calc(100% - 50px);
  padding-top: 50px;
}
#show .sidebar .menu-inner .menu-item {
  padding: 40px 30px;
}

@media (max-width: 1200px) {
  .mobile-btn-open {
    display: block;
  }
  .nav-container {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .logo-container {
    height: 55px !important;
  }
}
@media (min-width: 1200px) {
  .mobile-btn-open {
    display: none;
  }
}
.section-about-page {
  padding-top: 40px;
  padding-bottom: 80px;
}
.section-about-page .about-page-body {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 64px;
}
.section-about-page .about-page-body h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  max-width: 600px;
}
.section-about-page .about-page-body p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
}
.section-about-page .about-page-body img {
  width: 100%;
  max-height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 16px;
}
.section-about-page .about-page-body .sphere-big-container {
  position: absolute;
  bottom: -200px;
  right: 100px;
  animation: rotate-1 6s ease-in-out infinite;
}
.section-about-page .about-page-body .sphere-small-container {
  position: absolute;
  bottom: -80px;
  right: 320px;
  animation: rotate-2 6s ease-in-out infinite;
  z-index: 999;
}
.section-about-page .about-page-body-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}
.section-about-page .about-page-body-left .left-info {
  flex: 1 1 700px;
}
.section-about-page .about-page-body-left .left-image {
  flex: 1 1 500px;
}
.section-about-page .about-page-body-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.section-about-page .about-page-body-right .right-info {
  flex: 1 1 700px;
}
.section-about-page .about-page-body-right .right-image {
  flex: 1 1 500px;
}

@media screen and (max-width: 992.98px) {
  .section-about-page {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .section-about-page .about-page-body h1 {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    max-width: unset;
  }
  .section-about-page .about-page-body p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-top: 12px;
  }
  .section-about-page .about-page-body img {
    max-height: 300px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 16px;
  }
  .section-about-page .about-page-body .sphere-big-container {
    top: -150px;
    right: -20px;
    height: 80px;
    width: 80px;
  }
  .section-about-page .about-page-body .sphere-big-container img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .section-about-page .about-page-body .sphere-small-container {
    bottom: -95px;
    left: 10px;
    height: 70px;
    width: 90px;
  }
  .section-about-page .about-page-body .sphere-small-container img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
.contact-page {
  position: relative;
  padding: 0 80px;
}
.contact-page .sphere-big-container {
  position: absolute;
  top: 0;
  right: 150px;
  animation: rotate-1 6s ease-in-out infinite;
}
.contact-page .sphere-small-container {
  position: absolute;
  top: 50px;
  left: 10px;
  animation: rotate-2 6s ease-in-out infinite;
}
.contact-page .element-container {
  position: absolute;
  bottom: 150px;
  left: 80px;
  animation: rotate-2 6s ease infinite;
  z-index: 999;
}
.contact-page .title-container {
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  text-align: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 40px auto;
}
.contact-page .title-container h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: -2.501px;
  max-width: 750px;
}
.contact-page .title-container p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 800px;
}
.contact-page .contact-form form {
  display: flex;
  flex-direction: column;
}
.contact-page .contact-form form .fields-group {
  position: relative;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-radius: 12px;
  border: 3px solid rgba(105, 145, 88, 0.4);
  background: linear-gradient(226deg, rgba(255, 255, 255, 0.05) 24.07%, rgba(255, 255, 255, 0.01) 98.32%);
  -webkit-backdrop-filter: blur(22px);
          backdrop-filter: blur(22px);
  padding: 40px 50px;
}
.contact-page .contact-form form .fields-group .input-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 1 300px;
}
.contact-page .contact-form form .fields-group .input-item label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.contact-page .contact-form form .fields-group .input-item label span {
  color: #509851;
  margin-left: 6px;
  margin-top: 6px;
}
.contact-page .contact-form form .fields-group .form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.contact-page .contact-form form .fields-group .form-group input {
  padding: 22px 15px;
  color: #000;
  transition: 0.3s ease-in;
  border-radius: 12px;
  border: 1px solid #c1d0ba;
}
.contact-page .contact-form form .fields-group .form-group input::-moz-placeholder {
  color: #dedede;
}
.contact-page .contact-form form .fields-group .form-group input::placeholder {
  color: #dedede;
}
.contact-page .contact-form form .fields-group .form-group input:focus {
  border: 1px solid #bababa;
}
.contact-page .contact-form form .fields-group .message textarea {
  padding: 15px;
  color: #000;
  transition: 0.3s;
  border-radius: 12px;
  border: 1px solid #c1d0ba;
}
.contact-page .contact-form form .fields-group .message textarea::-moz-placeholder {
  color: #dedede;
}
.contact-page .contact-form form .fields-group .message textarea::placeholder {
  color: #dedede;
}
.contact-page .contact-form form .fields-group .message textarea:focus {
  border: 1px solid #bababa;
}
.contact-page .contact-form form input[type=submit] {
  position: relative;
  z-index: 999;
  border-radius: 12px;
  background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
  box-shadow: 2px 5px 0 #888;
  padding: 16px 32px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.639px;
  margin: 70px auto 0 auto;
  min-width: 280px;
  transition: all 0.3s ease-in;
}
.contact-page .contact-form form input[type=submit]:hover {
  box-shadow: none;
  transform: translate(2px, 5px);
}

@media screen and (max-width: 992.98px) {
  .contact-page {
    padding: unset;
  }
  .contact-page .sphere-big-container {
    top: 400px;
    right: -20px;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
  .contact-page .sphere-small-container {
    top: 70px;
    left: -20px;
  }
  .contact-page .element-container {
    top: 80px;
    left: -80px;
    max-width: 200px;
  }
  .contact-page .element-container img {
    width: 100%;
    height: 100%;
  }
  .contact-page .title-container {
    margin: 0 auto 30px auto;
    text-align: center;
  }
  .contact-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .contact-page .title-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .contact-page .contact-form form .fields-group {
    padding: 24px 12px;
    gap: 12px;
  }
  .contact-page .contact-form form .fields-group .form-group {
    gap: 12px;
  }
  .contact-page .contact-form form input[type=submit] {
    width: 100%;
    margin: 40px auto 0 auto;
  }
}
.influencers-page {
  position: relative;
}
.influencers-page .sphere-big-container {
  position: absolute;
  top: -120px;
  right: 250px;
  z-index: 999;
  animation: rotate-1 6s ease-in-out infinite;
}
.influencers-page .sphere-small-container {
  position: absolute;
  top: 150px;
  left: 100px;
  z-index: 999;
  animation: rotate-2 6s ease-in-out infinite;
}
.influencers-page .title-container {
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  text-align: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 40px auto;
}
.influencers-page .title-container h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: -2.501px;
}
.influencers-page .title-container p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 800px;
}
.influencers-page .influencers-wrapper {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
.influencers-page .influencers-wrapper .influencer-item {
  position: relative;
  z-index: 9999;
  border-radius: 30px;
  -webkit-backdrop-filter: blur(22.6331348419px);
          backdrop-filter: blur(22.6331348419px);
  transition: 0.25s ease-in;
  border: 1px solid rgba(102, 102, 102, 0.12);
}
.influencers-page .influencers-wrapper .influencer-item:hover {
  border: 1px solid #699158;
}
.influencers-page .influencers-wrapper .influencer-item-image {
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px 30px 0 0;
}
.influencers-page .influencers-wrapper .influencer-item-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.influencers-page .influencers-wrapper .influencer-item-content .name {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.349px;
}
.influencers-page .influencers-wrapper .influencer-item-content .count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.68);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.326px;
  text-transform: uppercase;
}
.influencers-page .influencers-wrapper .influencer-item-content .count span {
  color: #699158;
}
.influencers-page .influencers-wrapper .influencer-item-content .buttons {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.influencers-page .influencers-wrapper .influencer-item-content .buttons a {
  padding: 16px 32px;
  border-radius: 12px;
  background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
  box-shadow: 0px 4.623px 11.557px 0px #e7e7fb;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.6px;
}
.influencers-page-body {
  padding: 32px 0;
  background-color: #fff;
}
.influencers-page-body .see-all-btn {
  display: block;
  min-width: 280px;
  padding: 16px 32px;
  border: 1px solid #699158;
  border-radius: 12px;
  box-shadow: 2px 5px 0 #888;
  color: #699158;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.639px;
  margin: 40px auto 20px auto;
  transition: 0.3s ease;
}
.influencers-page-body .see-all-btn:hover {
  color: #fff;
  border: 1px solid #fff;
  background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
  box-shadow: none;
  transform: translate(2px, 5px);
}

@media screen and (max-width: 992.98px) {
  .influencers-page .sphere-big-container {
    top: -100px;
    left: -10px;
    max-width: 100px;
  }
  .influencers-page .sphere-big-container img {
    width: 100%;
    height: 100%;
  }
  .influencers-page .sphere-small-container {
    top: 150px;
    right: 50px;
    left: unset;
    max-width: 80px;
  }
  .influencers-page .sphere-small-container img {
    width: 100%;
    height: 100%;
  }
  .influencers-page .title-container {
    margin: 0 auto 30px auto;
  }
  .influencers-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .influencers-page .title-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .influencers-page .influencers-wrapper .influencer-item {
    border-radius: 16px;
  }
  .influencers-page .influencers-wrapper .influencer-item-image {
    height: 290px;
    border-radius: 15px 15px 0 0;
  }
  .influencers-page .influencers-wrapper .influencer-item-content {
    padding: 12px;
  }
  .influencers-page .influencers-wrapper .influencer-item-content .buttons a {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
  }
}
.section-not-found {
  padding-top: 100px;
  padding-bottom: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-not-found .error-body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.section-not-found .error-body .image-container {
  position: relative;
  background-image: url("../assets/images/background/404.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 600px;
  height: 400px;
  margin-bottom: -30px;
}
.section-not-found .error-body .image-container .sphere-medium-container {
  position: absolute;
  left: 32%;
  top: 21%;
  z-index: 99;
  animation: rotate-1 6s ease-in-out infinite;
  width: 230px;
  height: 230px;
}
.section-not-found .error-body .image-container .sphere-medium-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.section-not-found .error-body h1 {
  color: #fff;
  font-size: 128px;
  font-weight: 700;
}
.section-not-found .error-body h2 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 35px;
  font-weight: 700;
}
.section-not-found .error-body p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 35px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 62px;
}
.section-not-found .error-body .btn-return {
  display: inline-block;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.7px;
  padding: 15px 40px;
  transition: all 0.3s ease-in;
  border-radius: 12px;
  background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
  box-shadow: 2px 5px 0 #888;
  min-width: 210px;
}
.section-not-found .error-body .btn-return:hover {
  box-shadow: none;
  transform: translate(2px, 5px);
}

@media screen and (max-width: 991.98px) {
  .section-not-found {
    padding-top: unset;
    padding-bottom: 100px;
  }
  .section-not-found .error-body .image-container {
    background-image: url("../assets/images/background/404.svg");
    width: 300px;
    height: 300px;
    margin-bottom: -60px;
  }
  .section-not-found .error-body .image-container .sphere-medium-container {
    left: 31%;
    top: 31%;
    animation: rotate-1 6s ease-in-out infinite;
    width: 120px;
    height: 120px;
  }
  .section-not-found .error-body .image-container .sphere-medium-container img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .section-not-found .error-body h1 {
    color: #fff;
    font-size: 58px;
    font-weight: 600;
  }
  .section-not-found .error-body h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    font-weight: 600;
  }
  .section-not-found .error-body p {
    font-size: 18px;
    margin: 10px 0 20px 0;
  }
  .section-not-found .error-body .btn-return {
    font-size: 18px;
    font-weight: 600;
    padding: 12px 24px;
    min-width: 100%;
  }
}
.services-page {
  position: relative;
}
.services-page .sphere-big-container {
  position: absolute;
  top: -120px;
  right: 250px;
  z-index: 999;
  animation: rotate-1 6s ease-in-out infinite;
}
.services-page .sphere-small-container {
  position: absolute;
  top: 150px;
  left: 100px;
  z-index: 999;
  animation: rotate-2 6s ease-in-out infinite;
}
.services-page .title-container {
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  text-align: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 40px auto;
}
.services-page .title-container h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: -2.501px;
  max-width: 750px;
}
.services-page .title-container p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 800px;
}
.services-page .main-content {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.services-page .main-content .service-card {
  flex: 1 1 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 32px 24px;
  transition: 0.3s;
  border-radius: 25px;
  background: #32422a;
}
.services-page .main-content .service-card i {
  font-size: 70px;
  color: #fff;
}
.services-page .main-content .service-card img {
  width: 65px;
  height: 65px;
  -o-object-fit: contain;
     object-fit: contain;
}
.services-page .main-content .service-card h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin: 16px 0;
  min-height: 56px;
}
.services-page .main-content .service-card p {
  color: rgba(255, 255, 255, 0.68);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
.services-page .main-content .service-card span {
  font-size: 14px;
  font-weight: 400;
  color: #699158;
}
.services-page .main-content .service-card a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 36px;
}
.services-page .main-content .service-card a span {
  color: #f1f5f0;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
}
.services-page .main-content .service-card a img {
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
     object-fit: cover;
}
.services-page-body {
  padding: 48px 0;
  background-color: #fff;
}

@media screen and (max-width: 992.98px) {
  .services-page .sphere-big-container {
    top: 150px;
    right: 50px;
    max-width: 80px;
  }
  .services-page .sphere-big-container img {
    width: 100%;
    height: 100%;
  }
  .services-page .sphere-small-container {
    top: -100px;
    left: -10px;
    max-width: 100px;
  }
  .services-page .sphere-small-container img {
    width: 100%;
    height: 100%;
  }
  .services-page .title-container {
    margin: 0 auto 30px auto;
  }
  .services-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .services-page .title-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .services-page .main-content .service-card h2 {
    min-height: unset;
  }
}
.statistics-page .title-container {
  position: relative;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  text-align: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.statistics-page .title-container h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: -2.501px;
}
.statistics-page .title-container p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 800px;
}
.statistics-page .main-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: unset;
  flex-wrap: wrap;
  padding-bottom: 70px;
  gap: 25px;
}
.statistics-page .main-content .sphere-big-container {
  position: absolute;
  bottom: -90px;
  right: 0;
  z-index: 999;
  animation: rotate-1 6s ease-in-out infinite;
}
.statistics-page .main-content .sphere-small-container {
  position: absolute;
  top: -250px;
  left: -30px;
  z-index: 999;
  animation: rotate-2 6s ease-in-out infinite;
}
.statistics-page .main-content-left {
  flex: 1 1 200px;
}
.statistics-page .main-content-left .influencer-item {
  border-radius: 30px;
  transition: 0.25s ease-in;
  border: 1px solid rgba(102, 102, 102, 0.12);
  background: #fff;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.statistics-page .main-content-left .influencer-item:hover {
  border: 1px solid #699158;
}
.statistics-page .main-content-left .influencer-item-image {
  height: 300px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px 30px 0 0;
}
.statistics-page .main-content-left .influencer-item-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.statistics-page .main-content-left .influencer-item-content .name {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.349px;
}
.statistics-page .main-content-left .influencer-item-content .count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.68);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.326px;
  text-transform: uppercase;
}
.statistics-page .main-content-left .influencer-item-content .count span {
  color: #699158;
}
.statistics-page .main-content-left .influencer-item-content .buttons {
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-radius: 12px;
  box-shadow: 0px 5.106px 12.766px 0px #e7e7fb;
}
.statistics-page .main-content-left .influencer-item-content .buttons a {
  padding: 16px 32px;
  background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
  border-radius: 12px;
  box-shadow: 0px 4.623px 11.557px 0px #e7e7fb;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.6px;
}
.statistics-page .main-content-right {
  flex: 4 1 500px;
  position: relative;
  z-index: 999;
  padding: 24px 24px 12px 24px;
  box-shadow: 0px 5.106px 12.766px 0px rgba(220, 220, 223, 0.1921568627);
  border-radius: 32px;
  background: #fff;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.statistics-page .main-content-right .statistics-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  gap: 12px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents {
  flex: 1 1 250px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  padding: 0 6px 12px 6px;
  border-bottom: 0.5px solid #4e4e4f;
  text-wrap: nowrap;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li .country-wrapper {
  display: flex;
  gap: 8px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li img {
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li:last-child {
  border-bottom: unset;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul p {
  text-align: center;
  color: #fff;
  margin-bottom: 12px;
  color: #769c65;
}
.statistics-page .other-influencers-body {
  padding: 60px;
  background: #fff;
}
.statistics-page .other-influencers-body .other-influencers .title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  text-align: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 40px auto;
}
.statistics-page .other-influencers-body .other-influencers .title-container p {
  color: rgba(0, 0, 0, 0.68);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.statistics-page .other-influencers-body .other-influencers-wrapper {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item {
  border-radius: 30px;
  -webkit-backdrop-filter: blur(22.6331348419px);
          backdrop-filter: blur(22.6331348419px);
  transition: 0.25s ease-in;
  border: 1px solid rgba(102, 102, 102, 0.12);
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item:hover {
  border: 1px solid #699158;
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item-image {
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px 30px 0 0;
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item-content .name {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.349px;
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item-content .count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.68);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.326px;
  text-transform: uppercase;
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item-content .count span {
  color: #699158;
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item-content .buttons {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.statistics-page .other-influencers-body .other-influencers-wrapper-item-content .buttons a {
  padding: 16px 32px;
  border-radius: 12px;
  background: linear-gradient(260deg, #699158 -14.18%, rgba(155, 195, 138, 0.8) 118.97%);
  box-shadow: 0px 4.623px 11.557px 0px #e7e7fb;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.6px;
}

@media screen and (max-width: 992.98px) {
  .statistics-page .title-container {
    margin: 0 auto 30px auto;
  }
  .statistics-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .statistics-page .title-container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .statistics-page .main-content .sphere-big-container {
    bottom: -60px;
    right: 0;
    max-width: 120px;
  }
  .statistics-page .main-content .sphere-big-container img {
    width: 100%;
    height: 100%;
  }
  .statistics-page .main-content .sphere-small-container {
    top: -250px;
    left: -50px;
    max-width: 120px;
  }
  .statistics-page .main-content .sphere-small-container img {
    width: 100%;
    height: 100%;
  }
  .statistics-page .main-content-left {
    flex: 1 1 200px;
  }
  .statistics-page .main-content-left .influencer-item {
    border-radius: 16px;
  }
  .statistics-page .main-content-left .influencer-item-image {
    height: 290px;
    border-radius: 15px 15px 0 0;
  }
  .statistics-page .main-content-left .influencer-item-content {
    padding: 12px;
  }
  .statistics-page .main-content-left .influencer-item-content .buttons a {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
  }
  .statistics-page .main-content-right {
    border-radius: 16px;
  }
  .statistics-page .other-influencers-body {
    padding: 60px 0;
  }
  .statistics-page .other-influencers-body .other-influencers .title-container {
    margin: 0 auto 30px auto;
  }
  .statistics-page .other-influencers-body .other-influencers .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .statistics-page .other-influencers-body .other-influencers-wrapper-item {
    border-radius: 16px;
  }
  .statistics-page .other-influencers-body .other-influencers-wrapper-item-image {
    height: 290px;
    border-radius: 15px 15px 0 0;
  }
  .statistics-page .other-influencers-body .other-influencers-wrapper-item-content {
    padding: 12px;
  }
  .statistics-page .other-influencers-body .other-influencers-wrapper-item-content .buttons a {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
  }
}/*# sourceMappingURL=main.css.map */
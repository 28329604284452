.services-page {
  position: relative;
  .sphere-big-container {
    position: absolute;
    top: -120px;
    right: 250px;
    z-index: 999;
    animation: rotate-1 6s ease-in-out infinite;
  }
  .sphere-small-container {
    position: absolute;
    top: 150px;
    left: 100px;
    z-index: 999;
    animation: rotate-2 6s ease-in-out infinite;
  }
  .title-container {
    position: relative;
    z-index: 999;
    @include flexbox(center, center);
    gap: 12px;
    flex-direction: column;
    text-align: center;
    max-width: fit-content;
    margin: 0 auto 40px auto;
    h1 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: -2.501px;
      max-width: 750px;
    }
    p {
      color: rgba(0, 0, 0, 0.68);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      max-width: 800px;
    }
  }

  .main-content {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    .service-card {
      flex: 1 1 320px;
      @include flexbox(space-between, center);
      text-align: center;
      flex-direction: column;
      padding: 32px 24px;
      transition: 0.3s;
      border-radius: 25px;
      background: #32422a;
      i {
        font-size: 70px;
        color: #fff;
      }
      img {
        width: 65px;
        height: 65px;
        object-fit: contain;
      }
      h2 {
        color: #fff;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        margin: 16px 0;
        min-height: 56px;
      }
      p {
        color: rgba(255, 255, 255, 0.68);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #699158;
      }
      a {
        @include flexbox(center, center);
        gap: 6px;
        margin-top: 36px;
        span {
          color: #f1f5f0;
          font-size: 16px;
          font-weight: 600;
          line-height: 100%;
        }
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
  }
  &-body {
    padding: 48px 0;
    background-color: #fff;
  }
}


@media screen and (max-width: 992.98px) {
  .services-page {
    .sphere-big-container {
      top: 150px;
      right: 50px;
      max-width: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sphere-small-container {
      top: -100px;
      left: -10px;
      max-width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title-container {
      margin: 0 auto 30px auto;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .main-content {
      .service-card {
        h2 {
          min-height: unset ;
        }
      }
    }
  }
}
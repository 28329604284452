.section-customer {
  padding: 90px 0;
  background-color: #fff;
  .reviews {
    &-header {
      @include flexbox(center, center);
      flex-direction: column;
      margin-bottom: 80px;
      text-align: center;
      span {
        color: rgba(0, 0, 0, 0.87);
        font-size: 38px;
        font-weight: 700;
        line-height: 48px;
      }
      h2 {
        color: rgba(0, 0, 0, 0.68);
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        margin-top: 24px;
        max-width: 1000px;
      }
    }

    &-body {
      .swiper-container {
        padding: 20px 10px;
        .swiper-slide {
          border-radius: 32px;
          border: 1px solid rgba(102, 102, 102, 0.12);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.1) 100%
          );
          backdrop-filter: blur(25px);
          img {
            width: 100%;
            height: 330px;
            object-fit: cover;
            border-radius: 32px 32px 0 0;
          }
          .user-info {
            padding: 20px 12px;
            .name-rate-container {
              @include flexbox(flex-start, center);
              flex-wrap: wrap;
              gap: 12px;
              h2 {
                color: rgba(0, 0, 0, 0.87);
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
              }
              .rate {
                color: #699158;
              }
            }
            p {
              margin-top: 20px;
              color: rgba(0, 0, 0, 0.68);
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
      .swiper-container .swiper-slide {
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      .swiper-container .swiper-slide-active {
        transform: scale(1.01);
        border: 0.7px solid #699158;
        background: var(--Foundation-Green-green-50, #f1f5f0);
        backdrop-filter: blur(25px);
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .section-customer {
    padding: 70px 0;
    .reviews {
      &-header {
        margin-bottom: 40px;
        span {
          font-size: 32px;
          line-height: 40px;
        }
        h2 {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 12px;
        }
      }
      &-body {
        .swiper-container {
          .swiper-slide {
            border-radius: 16px;
            .user-info {
              padding: 12px;
            }
            img {
              height: 280px;
              border-radius: 16px 16px 0 0;
            }
          }
        }
      }
    }
  }
}
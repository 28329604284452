.statistics-page {
  .title-container {
    position: relative;
    z-index: 9999;
    @include flexbox(center, center);
    gap: 12px;
    flex-direction: column;
    text-align: center;
    max-width: fit-content;
    margin: 0 auto 60px auto;
    h1 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: -2.501px;
    }
    p {
      color: rgba(0, 0, 0, 0.68);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      max-width: 800px;
    }
  }

  .main-content {
    position: relative;
    @include flexbox(space-between, unset);
    flex-wrap: wrap;
    padding-bottom: 70px;
    gap: 25px;
    .sphere-big-container {
      position: absolute;
      bottom: -90px;
      right: 0;
      z-index: 999;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .sphere-small-container {
      position: absolute;
      top: -250px;
      left: -30px;
      z-index: 999;
      animation: rotate-2 6s ease-in-out infinite;
    }
    &-left {
      flex: 1 1 200px;
      .influencer-item {
        border-radius: 30px;
        transition: 0.25s ease-in;
        border: 1px solid rgba(102, 102, 102, 0.12);
        background: #fff;
        backdrop-filter: blur(25px);
        &:hover {
          border: 1px solid #699158;
        }
        &-image {
          height: 300px;
          background-position: center top;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 30px 30px 0 0;
        }
        &-content {
          display: flex;
          flex-direction: column;
          padding: 20px;

          .name {
            color: rgba(0, 0, 0, 0.87);
            font-size: 20px;
            font-weight: 700;
            line-height: 25.349px;
          }
          .count {
            @include flexbox(flex-start, center);
            gap: 8px;
            margin-top: 6px;
            margin-bottom: 20px;
            color: rgba(0, 0, 0, 0.68);
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.326px;
            text-transform: uppercase;
            span {
              color: #699158;
            }
          }

          .buttons {
            display: flex;
            flex-direction: column;
            gap: 14px;
            border-radius: 12px;
            box-shadow: 0px 5.106px 12.766px 0px #e7e7fb;
            a {
              padding: 16px 32px;
              background: linear-gradient(
                260deg,
                #699158 -14.18%,
                rgba(155, 195, 138, 0.8) 118.97%
              );
              border-radius: 12px;
              box-shadow: 0px 4.623px 11.557px 0px #e7e7fb;
              color: #fff;
              text-align: center;
              font-size: 18px;
              font-weight: 700;
              letter-spacing: -0.6px;
            }
          }
        }
      }
    }

    &-right {
      flex: 4 1 500px;
      position: relative;
      z-index: 999;
      padding: 24px 24px 12px 24px;
      box-shadow: 0px 5.106px 12.766px 0px #dcdcdf31;
      border-radius: 32px;
      background: #fff;
      backdrop-filter: blur(25px);
      .statistics-wrapper {
        @include flexbox(center, center);
        height: 100%;
        flex-wrap: wrap;
        gap: 12px;
        .statistics-contents {
          flex: 1 1 250px;
          ul {
            li {
              @include flexbox(space-between, center);
              gap: 12px;
              margin-bottom: 20px;
              padding: 0 6px 12px 6px;
              border-bottom: 0.5px solid #4e4e4f;
              text-wrap: nowrap;
              .country-wrapper {
                display: flex;
                gap: 8px;
              }
              img {
                width: 24px;
                height: 24px;
                object-fit: cover;
                border-radius: 50%;
              }
              span {
                color: rgba(0, 0, 0, 0.87);
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
              }
              p {
                color: rgba(0, 0, 0, 0.68);
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
              }
              &:last-child {
                border-bottom: unset;
              }
            }
            p {
              text-align: center;
              color: #fff;
              margin-bottom: 12px;
              color: #769c65;
            }
          }
        }
      }
    }
  }

  .other-influencers-body {
    padding: 60px;
    background: #fff;
    .other-influencers {
      .title-container {
        @include flexbox(center, center);
        gap: 12px;
        flex-direction: column;
        text-align: center;
        max-width: fit-content;
        margin: 0 auto 40px auto;
        p {
          color: rgba(0, 0, 0, 0.68);
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      &-wrapper {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        &-item {
          border-radius: 30px;
          backdrop-filter: blur(22.633134841918945px);
          transition: 0.25s ease-in;
          border: 1px solid rgba(102, 102, 102, 0.12);
          &:hover {
            border: 1px solid #699158;
          }
          &-image {
            height: 300px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 30px 30px 0 0;
          }
          &-content {
            display: flex;
            flex-direction: column;
            padding: 20px;
            .name {
              color: rgba(0, 0, 0, 0.87);
              font-size: 20px;
              font-weight: 700;
              line-height: 25.349px;
            }
            .count {
              @include flexbox(flex-start, center);
              gap: 8px;
              margin-top: 6px;
              margin-bottom: 20px;
              color: rgba(0, 0, 0, 0.68);
              font-size: 18px;
              font-weight: 700;
              letter-spacing: 0.326px;
              text-transform: uppercase;
              span {
                color: #699158;
              }
            }

            .buttons {
              display: flex;
              flex-direction: column;
              gap: 14px;
              a {
                padding: 16px 32px;
                border-radius: 12px;
                background: linear-gradient(
                  260deg,
                  #699158 -14.18%,
                  rgba(155, 195, 138, 0.8) 118.97%
                );
                box-shadow: 0px 4.623px 11.557px 0px #e7e7fb;
                color: #fff;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: -0.6px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .statistics-page {
    .title-container {
      margin: 0 auto 30px auto;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .main-content {
      .sphere-big-container {
        bottom: -60px;
        right: 0;
        max-width: 120px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .sphere-small-container {
        top: -250px;
        left: -50px;
        max-width: 120px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-left {
        flex: 1 1 200px;
        .influencer-item {
          border-radius: 16px;
          &-image {
            height: 290px;
            border-radius: 15px 15px 0 0;
          }
          &-content {
            padding: 12px;
            .buttons {
              a {
                padding: 12px 24px;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
        }
      }

      &-right {
        border-radius: 16px;
      }
    }

    .other-influencers-body {
      padding: 60px 0;
      .other-influencers {
        .title-container {
          margin: 0 auto 30px auto;
          h1 {
            font-size: 32px;
            line-height: 40px;
          }
        }
        &-wrapper {
          &-item {
            border-radius: 16px;
            &-image {
              height: 290px;
              border-radius: 15px 15px 0 0;
            }
            &-content {
              padding: 12px;
              .buttons {
                a {
                  padding: 12px 24px;
                  font-size: 16px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}

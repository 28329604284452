.telegram-agents-contacts {
  padding: 45px 15px;
  border-radius: 12px;
  background: #fff;
  margin-top: 150px;
  margin-bottom: 90px;
  .agents-contacts {
    @include flexbox(center, center);
    gap: 25px;
    flex-wrap: wrap;
    &-item {
      flex: 1 1 300px;
      padding: 20px;
      a {
        @include flexbox(center, center);
        flex-wrap: wrap;
        gap: 16px;
        .telegram-image-container {
          @include flexbox(center, center);
          width: 50px;
          height: 50px;
          background-color: #f0f4ee;
          border-radius: 50%;
          transition: 0.3s ease-in;
          img {
            width: 24px;
            height: 24px;
            margin-left: -5px;
          }
          &:hover {
            transform: rotate(360deg);
          }
        }
        span {
          color: rgba(0, 0, 0, 0.87);
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .telegram-agents-contacts {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 110px;
    margin-bottom: unset;
    border-radius: unset;
    .agents-contacts {
      gap: unset;
      &-item {
        padding: 25px 0;
        a {
          justify-content: flex-start;
          max-width: 350px;
          margin: 0 auto;
          span {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.section-benefits {
  padding-top: 110px;
  padding-bottom: 180px;
  .content {
    position: relative;
    .info-text {
      margin-bottom: 52px;
      h1 {
        color: rgba(0, 0, 0, 0.87);
        font-size: 38px;
        font-weight: 700;
        line-height: 48px;
      }
      p {
        color: rgba(0, 0, 0, 0.68);
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        max-width: 650px;
        margin-top: 8px;
      }
    }
    .sphere-medium-container {
      position: absolute;
      right: -80px;
      bottom: -80px;
      z-index: 99;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .body {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 40px;
      .item {
        position: relative;
        z-index: 998;
        flex-direction: column;
        padding: 32px 16px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border: 0.5px solid #699158;
        border-radius: 12px;
        background: linear-gradient(
          226deg,
          rgba(255, 255, 255, 0.05) 24.07%,
          rgba(255, 255, 255, 0.01) 98.32%
        );
        backdrop-filter: blur(22px);
        &:hover {
          transform: translateY(-10px);
          border: 0.5px solid #699158;
        }
        h2 {
          text-align: center;
          color: rgba(0, 0, 0, 0.87);
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
        }
        p {
          color: rgba(0, 0, 0, 0.68);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        .line-element {
          margin: 24px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .section-benefits {
    padding-top: 80px;
    padding-bottom: 180px;
    .content {
      .info-text {
        margin-bottom: 32px;
        h1 {
          font-size: 32px;
          line-height: 40px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .body {
       gap: 25px; 
      }
    }
  }
}
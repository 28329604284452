.section-hero {
  position: relative;
  height: calc(100vh - 290px);
  .container {
    height: 100%;
    .content {
      position: relative;
      z-index: 9999;
      @include flexbox(center, center);
      flex-direction: column;
      height: 100%;
      text-align: center;
      h1 {
        background: linear-gradient(
          90deg,
          #b4adc1 5.26%,
          #8fb698 45.69%,
          #4f9750 64.36%,
          #7c7a80 80%
        );
        max-width: 850px;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 75px;
        font-weight: 700;
        line-height: 84px;
        margin: 0 auto;
      }
      p {
        color: rgba(0, 0, 0, 0.68);
        font-size: 26px;
        font-weight: 500;
        line-height: 36px;
        margin-top: 20px;
        max-width: 1000px;
      }
      a {
        display: inline-block;
        color: #fff;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.7px;
        padding: 15px 40px;
        transition: all 0.3s ease-in;
        border-radius: 12px;
        background: linear-gradient(
          260deg,
          #699158 -14.18%,
          rgba(155, 195, 138, 0.8) 118.97%
        );
        box-shadow: 2px 5px 0 #888;
        min-width: 210px;
        margin-top: 40px;
        margin-bottom: 80px;
        &:hover {
          box-shadow: none;
          transform: translate(2px, 5px);
        }
      }
    }
  }

  .sphere-big-container {
    position: absolute;
    left: -40px;
    bottom: -50px;
    z-index: 999;
    animation: top-bottom-animation 6s ease-in-out infinite;
  }
  .sphere-small-container {
    position: absolute;
    top: 50px;
    left: 150px;
    z-index: 999;
    animation: rotate-2 6s ease-in-out infinite;
  }
  .sphere-extra-small-container {
    position: absolute;
    bottom: 50px;
    right: 150px;
    z-index: 999;
    animation: rotate-1 6s ease-in-out infinite;
  }
  .element-container {
    position: absolute;
    top: -140px;
    right: 0;
    z-index: 999;
    animation: left-right-animation 6s ease infinite;
  }
}

@media screen and (max-width: 992.98px) {
  .section-hero {
    height: calc(100vh - 300px);
    .container {
      .content {
        h1 {
          font-size: 32px;
          line-height: 40px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        a {
          text-align: center;
          width: 100%;
        }
      }
    }
    .sphere-big-container {
      max-width: 120px;
      max-height: 120px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .sphere-small-container {
      top: -50px;
      left: 15px;
      max-width: 70px;
      max-height: 70px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .sphere-extra-small-container {
      bottom: 100px;
      right: 20px;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .element-container {
      top: -70px;
      right: 20px;
      animation: left-right-animation 6s ease infinite;
      max-width: 100px;
      max-height: 100px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.footer {
  background: #131313;
  .footer-content {
    @include flexbox(space-between, unset);
    gap: 50px;
    flex-wrap: wrap;
    padding-top: 80px;
    padding-bottom: 50px;
    border-top: 0.3px solid rgba(255, 255, 255, 0.6);
    .footer-info {
      @include flexbox(flex-start, flex-start);
      flex-direction: column;
      flex-wrap: wrap;
      gap: 20px;
      .main-logo {
        min-height: 60px;
        object-fit: cover;
      }
      .footer-short-info {
        max-width: 500px;
        color: #c8c8c8;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.28px;
      }

      .footer-social-network {
        @include flexbox(flex-start, flex-start);
        gap: 12px;
        a {
          @include flexbox(center, center);
          width: 32px;
          height: 32px;
          background-color: #323132;
          border-radius: 50%;
          transition: 0.3s ease-in-out;
          &:hover {
            transform: rotate(360deg);
          }
          img {
            width: 16px;
            height: 16px;
            object-fit: cover;
            margin-left: -3px;
          }
        }
      }
    }

    .footer-nav {
      h2 {
        color: #efeeef;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        li {
          a {
            color: #c8c8c8;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.28px;
            &.active {
              color: #769c65;
            }
          }
        }
      }
    }
  }
  .copyright-area {
    padding-top: 10px;
    padding-bottom: 30px;
    p {
      color: #a8a8a8;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 992.98px) {
  .footer {
    .footer-content {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}

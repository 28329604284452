.contact-page {
  position: relative;
  padding: 0 80px;
  .sphere-big-container {
    position: absolute;
    top: 0;
    right: 150px;
    animation: rotate-1 6s ease-in-out infinite;
  }
  .sphere-small-container {
    position: absolute;
    top: 50px;
    left: 10px;
    animation: rotate-2 6s ease-in-out infinite;
  }
  .element-container {
    position: absolute;
    bottom: 150px;
    left: 80px;
    animation: rotate-2 6s ease infinite;
    z-index: 999;
  }
  .title-container {
    position: relative;
    z-index: 999;
    @include flexbox(center, center);
    gap: 12px;
    flex-direction: column;
    text-align: center;
    max-width: fit-content;
    margin: 0 auto 40px auto;
    h1 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: -2.501px;
      max-width: 750px;
    }
    p {
      color: rgba(0, 0, 0, 0.68);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      max-width: 800px;
    }
  }

  .contact-form {
    form {
      display: flex;
      flex-direction: column;
      .fields-group {
        position: relative;
        z-index: 999999;
        display: flex;
        flex-direction: column;
        gap: 25px;
        border-radius: 12px;
        border: 3px solid rgba(105, 145, 88, 0.4);
        background: linear-gradient(
          226deg,
          rgba(255, 255, 255, 0.05) 24.07%,
          rgba(255, 255, 255, 0.01) 98.32%
        );
        backdrop-filter: blur(22px);
        padding: 40px 50px;
        .input-item {
          display: flex;
          flex-direction: column;
          gap: 12px;
          flex: 1 1 300px;
          label {
            @include flexbox(flex-start, center);
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            span {
              color: #509851;
              margin-left: 6px;
              margin-top: 6px;
            }
          }
        }
        .form-group {
          display: flex;
          flex-wrap: wrap;
          gap: 25px;
          input {
            padding: 22px 15px;
            color: #000;
            transition: 0.3s ease-in;
            border-radius: 12px;
            border: 1px solid #c1d0ba;
            &::placeholder {
              color: #dedede;
            }
            &:focus {
              border: 1px solid #bababa;
            }
          }
        }
        .message {
          textarea {
            padding: 15px;
            color: #000;
            transition: 0.3s;
            border-radius: 12px;
            border: 1px solid #c1d0ba;
            &::placeholder {
              color: #dedede;
            }
            &:focus {
              border: 1px solid #bababa;
            }
          }
        }
      }
      input[type="submit"] {
        position: relative;
        z-index: 999;
        border-radius: 12px;
        background: linear-gradient(
          260deg,
          #699158 -14.18%,
          rgba(155, 195, 138, 0.8) 118.97%
        );
        box-shadow: 2px 5px 0 #888;
        padding: 16px 32px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.639px;
        margin: 70px auto 0 auto;
        min-width: 280px;
        transition: all 0.3s ease-in;
        &:hover {
          box-shadow: none;
          transform: translate(2px, 5px);
        }
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .contact-page {
    padding: unset;
    .sphere-big-container {
      top: 400px;
      right: -20px;
      max-height: fit-content;
    }
    .sphere-small-container {
      top: 70px;
      left: -20px;
    }
    .element-container {
      top: 80px;
      left: -80px;
      max-width: 200px; 
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title-container {
      margin: 0 auto 30px auto;
      text-align: center;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .contact-form {
      form {
        .fields-group {
          padding: 24px 12px;
          gap: 12px;
          .form-group {
            gap: 12px;
          }
        }
        input[type="submit"] {
          width: 100%;
          margin: 40px auto 0 auto;
        }
      }
    }
  }
}

.services-amount-body {
  padding-top: 200px;
  padding-bottom: 200px;
  .container {
    position: relative;
    .sphere_big_wrapper,
    .sphere_small_wrapper {
      position: absolute;
      max-height: fit-content;
    }
    .sphere_big_wrapper {
      left: 20px;
      bottom: -90px;
      animation: rotate-2 6s ease-in-out infinite;
    }
    .sphere_small_wrapper {
      top: -40px;
      right: 30px;
      animation: rotate-1 6s ease-in-out infinite;
    }
  }
  .services-amount-wrapper {
    @include flexbox(space-between, normal);
    gap: 24px;
    flex-wrap: wrap;
    border-radius: 32px;
    background: linear-gradient(
      95deg,
      rgba(255, 255, 255, 0.4) -14.5%,
      rgba(255, 255, 255, 0.1) 118.65%
    );
    backdrop-filter: blur(25px);
    padding: 32px 64px;
    z-index: 99;
    position: relative;
    .item-container {
      flex: 1 1 300px;
      user-select: none;
      @include flexbox(space-between, center);
      flex-direction: column;
      border-right: 1.5px solid rgba(105, 145, 88, 0.53);
      padding: 40px 0;
      transition: 0.3s ease-in;
      &:last-child {
        border-right: unset;
      }
      &:hover {
        transform: translateY(-8px);
      }
      h3 {
        display: flex;
        gap: 12px;
        color: #699158;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        margin-bottom: 10px;
        p {
          color: #699158;
          font-size: 48px;
          font-weight: 700;
          line-height: 56px;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.68);
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-wrap: nowrap;
      }
    }
  }
}

@media screen and (min-width: 993px) and (max-width: 1105px) {
  .services-amount-body {
    .services-amount-wrapper {
      .item-container {
        &:nth-child(2) {
          border-right: unset;
        }
      }
    }
  }
}

@media screen and (min-width: 678px) and (max-width: 977px) {
  .services-amount-body {
    .services-amount-wrapper {
      .item-container {
        &:nth-child(3) {
          border-bottom: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .services-amount-body {
    padding-top: 100px;
    padding-bottom: 100px;
    .container {
      .sphere_big_wrapper {
        top: -60px;
        left: -50px;
      }
      .sphere_small_wrapper {
        top: 400px;
        right: -10px;
      }
    }
    .services-amount-wrapper {
      gap: unset;
      padding: 24px;
      .item-container {
        border-right: unset;
        border-bottom: 1.5px solid rgba(105, 145, 88, 0.53);
        justify-content: center;
        padding: 60px 0;
        &:hover {
          transform: unset;
        }
        &:last-child {
          border-bottom: unset;
        }
      }
    }
  }
}

.header {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  transition: all 0.3s ease;
  padding: 19px 0;
  &.fixed {
    background: #ffffff;
    padding: 10px 0;
    border-bottom: 0.2px solid #699158;
    border-radius: 0 0 16px 16px;
  }
  .header-container {
    @include flexbox(space-between, center);
    .mobile-btn-open {
      img {
        width: 28px;
        height: 28px;
        object-fit: cover;
        margin-top: 8px;
      }
    }
    .logo-container {
      height: 65px;
      width: 125px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: drop-shadow(1px 1px -1px black);
      }
    }
    .nav-container {
      ul {
        @include flexbox(center, center);
        gap: 36px;
      }
      &-item {
        color: rgba(0, 0, 0, 0.68);
        font-size: 16px;
        font-weight: 400;
        transition: 0.2s ease-in;
        text-transform: uppercase;
        line-height: 20px;
        padding-bottom: 2px;
        &:hover {
          color: rgba(0, 0, 0, 0.87);
        }
        &.active {
          color: rgba(0, 0, 0, 0.97);
          border-bottom: 2px solid #699158;
        }
      }
    }

    .contact-container {
      transition: 0.4s ease-in-out;
      &:hover {
        transform: rotate(360deg);
      }
      a {
        @include flexbox(center, center);
        width: 40px;
        height: 40px;
        font-size: 40px;
        background-color: #769c65;
        border-radius: 50%;
        img {
          width: 20px;
          height: 20px;
          margin-left: -5px;
        }
      }
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    padding: 35px 25px;
    background: #fff;
    transition: all 0.3s ease-out;
    transform: translateX(-100vw);
    transition: 0.3s ease-in;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    &-header {
      .close-button {
        float: right;
      }
    }
    &-body {
      margin-top: 150px;
      li {
        padding: 12px 0;
        text-align: center;
        a {
          color: rgba(0, 0, 0, 0.87);
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-transform: uppercase;
          &.active {
            color: #509851 !important;
          }
        }
      }
    }
  }
}

#show {
  .sidebar {
    transform: translateX(0vw);
    min-width: 80%;
    .menu-inner {
      top: 0;
      height: calc(100% - 50px);
      padding-top: 50px;
      .menu-item {
        padding: 40px 30px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .mobile-btn-open {
    display: block;
  }

  .nav-container {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .logo-container {
    height: 55px !important;
  }
}

@media (min-width: 1200px) {
  .mobile-btn-open {
    display: none;
  }
}

@keyframes rotate-1 {
  0% {
    transform: rotate(0) translateY(0);
  }

  50% {
    transform: rotate(50deg) translateY(0);
  }

  100% {
    transform: rotate(0) translateY(0);
  }
}

@keyframes rotate-2 {
  0% {
    transform: rotate(0) translateY(0);
  }

  50% {
    transform: rotate(-50deg) translateY(0);
  }

  100% {
    transform: rotate(0) translateY(0);
  }
}

@keyframes left-right-animation {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(40px);
  }

  100% {
    transform: translateX(0);
  }
}


@keyframes top-bottom-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}



@keyframes scale-1 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-2 {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes line-element {
  0% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 0% 100%;
  }

  100% {
      background-position: 0% 0%;
  }
}
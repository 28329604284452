.influencers-page {
  position: relative;
  .sphere-big-container {
    position: absolute;
    top: -120px;
    right: 250px;
    z-index: 999;
    animation: rotate-1 6s ease-in-out infinite;
  }
  .sphere-small-container {
    position: absolute;
    top: 150px;
    left: 100px;
    z-index: 999;
    animation: rotate-2 6s ease-in-out infinite;
  }
  .title-container {
    position: relative;
    z-index: 999;
    @include flexbox(center, center);
    gap: 12px;
    flex-direction: column;
    text-align: center;
    max-width: fit-content;
    margin: 0 auto 40px auto;
    h1 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: -2.501px;
    }
    p {
      color: rgba(0, 0, 0, 0.68);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      max-width: 800px;
    }
  }
  .influencers-wrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    .influencer-item {
      position: relative;
      z-index: 9999;
      border-radius: 30px;
      backdrop-filter: blur(22.633134841918945px);
      transition: 0.25s ease-in;
      border: 1px solid rgba(102, 102, 102, 0.12);
      &:hover {
        border: 1px solid #699158;
      }
      &-image {
        height: 300px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 30px 30px 0 0;
      }
      &-content {
        display: flex;
        flex-direction: column;
        padding: 20px;
        .name {
          color: rgba(0, 0, 0, 0.87);
          font-size: 20px;
          font-weight: 700;
          line-height: 25.349px;
        }
        .count {
          @include flexbox(flex-start, center);
          gap: 8px;
          margin-top: 6px;
          margin-bottom: 20px;
          color: rgba(0, 0, 0, 0.68);
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0.326px;
          text-transform: uppercase;
          span {
            color: #699158;
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          gap: 14px;
          a {
            padding: 16px 32px;
            border-radius: 12px;
            background: linear-gradient(
              260deg,
              #699158 -14.18%,
              rgba(155, 195, 138, 0.8) 118.97%
            );
            box-shadow: 0px 4.623px 11.557px 0px #e7e7fb;
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.6px;
          }
        }
      }
    }
  }
  &-body {
    padding: 32px 0;
    background-color: #fff;
    .see-all-btn {
      display: block;
      min-width: 280px;
      padding: 16px 32px;
      border: 1px solid #699158;
      border-radius: 12px;
      box-shadow: 2px 5px 0 #888;
      color: #699158;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.639px;
      margin: 40px auto 20px auto;
      transition: 0.3s ease;
      &:hover {
        color: #fff;
        border: 1px solid #fff;
        background: linear-gradient(
          260deg,
          #699158 -14.18%,
          rgba(155, 195, 138, 0.8) 118.97%
        );
        box-shadow: none;
    transform: translate(2px, 5px);
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .influencers-page {
    .sphere-big-container {
      top: -100px;
      left: -10px;
      max-width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sphere-small-container {
      top: 150px;
      right: 50px;
      left: unset;
      max-width: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title-container {
      margin: 0 auto 30px auto;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .influencers-wrapper {
      .influencer-item {
        border-radius: 16px;
        &-image {
          height: 290px;
          border-radius: 15px 15px 0 0;
        }
        &-content {
          padding: 12px;
          .buttons {
            a {
              padding: 12px 24px;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  scroll-behavior: smooth;
  font-family: Montserrat, sans-serif;
  background: linear-gradient(0deg, #ebefec 0%, #ebefec 100%),
    linear-gradient(158deg, #14171c 2.09%, #14171c 137.51%);
  overflow-x: hidden;
}

.app {
  padding-top: 120px;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

input,
textarea {
  border: none;
  background: none;
}

.container {
  padding: 0 15px;
  max-width: 1450px;
  margin: 0 auto;
}

.line-element {
  height: 2px;
  background-size: 300% 100%;
  animation: line-element 6s linear infinite;
  margin: 12px 0;
  filter: drop-shadow(0px 16px 30px #699158);
  border-radius: 12px;
  background-image: linear-gradient(
    90deg,
    rgb(206, 206, 206),
    #699158,
    rgba(104, 124, 106, 0.68),
    #699158
  );
}

.Toastify__toast-container--top-center {
  z-index: 999999 !important;
}

.swiper-button-prev,
.swiper-button-next {
  @include flexbox(center, center);
  padding: 12px;
  background-color: #699158;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  user-select: none !important;
  &::after {
    content: "" !important;
    user-select: none !important;
  }
}
.swiper-button-prev {
  background-image: url("../assets/icons/main/arrow-left.svg");
  transition: 0.3s ease-in;
  width: 35px;
  height: 35px;
  padding: 20px;
  &:hover {
    transform: rotate(-360deg);
  }
}
.swiper-button-next {
  background-image: url("../assets/icons/main/arrow-right.svg");
  transition: 0.3s ease-in;
  width: 35px;
  height: 35px;
  padding: 20px;
  &:hover {
    transform: rotate(360deg);
  }
}

.page-main {
  padding-top: 110px;
}

@media screen and (max-width: 767.98px) {
  .mobile-hidden-2 {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .mobile_hidden {
    display: none;
  }
}

.section-information {
  padding-top: 70px;
  padding-bottom: 90px;
  background: #fff;
  .content {
    &-main {
      h2 {
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        font-size: 38px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 70px;
      }
      ul {
        @include flexbox(center, center);
        flex-wrap: wrap;
        gap: 100px;
        li {
          position: relative;
          background-size: contain;
          background-repeat: no-repeat;
          height: 200px;
          width: 350px;
          &:nth-child(1) {
            background-image: url("../assets/images/background/1.svg");
          }
          &:nth-child(2) {
            background-image: url("../assets/images/background/2.svg");
          }
          &:nth-child(3) {
            background-image: url("../assets/images/background/3.svg");
          }
          .solution-info {
            position: absolute;
            bottom: -20px;
            left: 15px;
            span {
              color: rgba(0, 0, 0, 0.87);
              font-size: 22px;
              font-weight: 700;
              line-height: 28px;
              transition: all 0.3s ease-in;
            }
            p {
              color: rgba(0, 0, 0, 0.68);
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              max-width: 350px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .section-information {
    .content {
      &-main {
        h2 {
          font-size: 32px;
          line-height: 40px;
        }
        ul {
          gap: 60px;
          @include flexbox(unset, unset);
          li {
            height: 170px;
            .solution-info {
              left: unset;
              span {
                font-size: 20px;
                font-weight: 600;
                margin-left: unset;
              }
              p {
                font-size: 18px;
                max-width: unset;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }
}

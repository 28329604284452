.section-about-page {
  padding-top: 40px;
  padding-bottom: 80px;
  .about-page-body {
    position: relative;
    @include flexbox(space-between, center);
    flex-wrap: wrap;
    gap: 64px;
    h1 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 48px;
      font-weight: 700;
      line-height: 56px;
      max-width: 600px;
    }
    p {
      color: rgba(0, 0, 0, 0.68);
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 24px;
    }
    img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      border-radius: 16px;
    }
    .sphere-big-container {
      position: absolute;
      bottom: -200px;
      right: 100px;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .sphere-small-container {
      position: absolute;
      bottom: -80px;
      right: 320px;
      animation: rotate-2 6s ease-in-out infinite;
      z-index: 999;
    }
    &-left {
      @include flexbox(space-between, center);
      flex-wrap: wrap;
      gap: 24px;
      .left-info {
        flex: 1 1 700px;
      }
      .left-image {
        flex: 1 1 500px;
      }
    }

    &-right {
      @include flexbox(space-between, center);
      flex-wrap: wrap-reverse;
      gap: 24px;
      .right-info {
        flex: 1 1 700px;
      }
      .right-image {
        flex: 1 1 500px;
      }
    }
  }
}

@media screen and (max-width: 992.98px) {
  .section-about-page {
    padding-top: 20px;
    padding-bottom: 60px;
    .about-page-body {
      h1 {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        max-width: unset;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-top: 12px;
      }
      img {
        max-height: 300px;
        object-fit: cover;
        border-radius: 16px;
      }
      .sphere-big-container {
        top: -150px;
        right: -20px;
        height: 80px;
        width: 80px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .sphere-small-container {
        bottom: -95px;
        left: 10px;
        height: 70px;
        width: 90px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
